import '../../sass/components/_block_about-form.scss';

document.addEventListener('DOMContentLoaded', function () {
    const currentDateTimeField = document.getElementById('current-date-time');
    if (currentDateTimeField) {
        const now = new Date();
        const formattedDateTime = `${now.getFullYear()}-${`0${
            now.getMonth() + 1
        }`.slice(-2)}-${`0${now.getDate()}`.slice(
            -2
        )} ${`0${now.getHours()}`.slice(-2)}:${`0${now.getMinutes()}`.slice(
            -2
        )}:${`0${now.getSeconds()}`.slice(-2)}`;
        currentDateTimeField.value = formattedDateTime;
    }
});
